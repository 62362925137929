<template>
  <div class="mt-5 banner" id="home">
    <b-row align-v="center" class="h-100 justify-content-md-center">
      <b-col class="mb-sm-5" md="5" lg="5" order-md="2" order-lg="2">
        <h1 class="mb-5 hero-header">4D Digital Green Corridor</h1>
        <p class="hero-text">
          An initiative of the African Union and it's partners to drive the
          adoption of climate-smart and ecological sound technologies by
          Africa's industrial operators, and thus
          <strong>create green jobs in urban corridors</strong> for the
          continent's teeming youth.
        </p>
      </b-col>
      <b-col md="7" lg="7" order-md="1" order-lg="1"
        ><img
          src="@/assets/images/banner-img.png"
          alt="banner image"
          style="width: 100%;"
      /></b-col>
    </b-row>
  </div>
</template>

<script>
import url from '@/helpers/url'
import service from '@/helpers/service'
export default {
  name: "Banner",
  methods: {
    url(){
      return url()
    },
    service() {
      return service()
    }
  }
};
</script>

<style>
.hero-text {
  font-weight: 2em;
  font-size: 1.5em;
}
.banner {
  padding: 3em;
}
.hero-header {
  font-weight: bolder;
  font-size: 3rem;
}

@media (max-width: 767px) {
  .h-100 * {
    text-align: center;
  }
}
</style>
