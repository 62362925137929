<template>
  <div class="middle" id="about">
    <div class="middle-content">
      <div>
        <h1 class="mb-5">What you need to know</h1>
        <p>
          The 4D Digital Green Corridor is a "multistakeholder innovation"
          concept promoted by the African Union and its partners and organs;
          major governments; green tech industries; AfroChampions; and many
          leading technology innovators and accelerators.
        </p>

        <p>
          It connects and deepens the value chains that will power Africa's
          industrialisation through climate-smart technology transfer, creating
          vast numbers of jobs eventually for Africa's teeming youth.
        </p>

        <p>
          Leveraging major forces like AfCFTA and the Green Recovery Action Plan
          for Africa (GRAP), it is removing barriers and obstacles in the way of
          smart trade in Africa and accelerating the circulation of the talents
          and skills that will boost African integration for continental
          transformation.
        </p>
          <b-button variant="primary" :href="`${url()}/accounts/register`">Register</b-button>
      </div>

      <div>
        <img src="@/assets/images/bike.png" alt="bike" style="width: 25rem;" />
      </div>
    </div>

    <div class="standing">
      <img
        src="@/assets/images/standing.png"
        alt="standing"
        style="width: 20em;"
      />
    </div>
  </div>
</template>

<script>
import url from "@/helpers/url";
import service from "@/helpers/service";
export default {
  name: "Middle",
  methods: {
    register() {
      window.location.href = `${url()}/accounts/register`;
    },
    url() {
      return url();
    },
    service() {
      return service();
    },
    getUser() {
      this.user = this.$session.get("entity");
    },
  },
};
</script>

<style>
.middle {
  background-image: url("../../assets/images/background.png");
  background-repeat: no-repeat;
  background-size: cover;
}
h1 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.middle img {
  margin-bottom: 8rem;
  padding-right: 3rem;
}

.standing {
  text-align: center;
}

.standing img {
  margin-bottom: -8.3em;
}
.register-btn {
  width: 200px;
  height: 50px;
  margin-top: 2em;
  outline: none;
  border: none;
}
p {
  font-size: 1.4em;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.middle-content {
  margin-top: 7rem;
  margin-bottom: 7rem;
  display: flex;
  padding: 3em;
}

.middle-content h1 {
  font-size: 3rem;
}

@media (max-width: 960px) {
  .middle-content {
    flex-wrap: wrap-reverse;
  }

  .middle-content h1 {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .middle {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  h1 {
    font-size: 2.5rem;
    text-align: justify;
  }
}
</style>
