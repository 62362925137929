<template>
  <div id="app">
    <TopBar :scrollPosition="scrollPosition" />
    <Banner />
    <Middle />
    <Footer />
  </div>
</template>

<script>
import TopBar from "./TopBar.vue";
import Banner from "./Banner.vue";
import Middle from "./Middle.vue";
import Footer from "./Footer.vue";

export default {
  name: "App",
  components: { TopBar, Banner, Middle, Footer },
  data: () => ({
    scrollPosition: null,
  }),
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>

<style lang="scss">
  @import "@/assets/styles/_custom.scss";
  @font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI"),
   url('../../assets/Segoe-UI-Font/SEGOEUI.TTF') format("truetype");
}
#app {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #f8f9fa;
  overflow-x: hidden;
}
h1 {
  font-size: 4.3rem;
  font-weight: bolder;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
</style>
<style>
