<template>
  <footer class="greenFooter pt-5 pb-2" id="partners">
    <div class="center pb-5">
      <h1 class="mb-5 mt-3" id="strategic_partners">Strategic Partners</h1>
      <b-row align-v="center greenFooter-row mx-auto">
        <b-col md="4" lg="4">
          <div class="h-80">
            <img
              src="@/assets/images/footer-img-1.png"
              alt="advert"
              style="width: 30%"
            />
          </div>
          <p>
            Supporting AfCFTA Implementation
          </p>
        </b-col>

        <b-col md="4" lg="4">
          <div class="h-80">
            <img
              src="@/assets/images/footer-img-2.png"
              alt="advert"
              style="width: 30%"
            />
          </div>
          <p class="mt-4">
            Affiliated with the Sankoree Institute
          </p>
        </b-col>

        <b-col md="4" lg="4">
          <div class="h-80 mt-5">
            <img
              src="@/assets/images/footer-img-3.png"
              alt="advert"
              style="width:50%"
            />
          </div>
          <p class="mt-5">
            In association with the AU Green Recovery Action Plan & Open
            Corridors Initiative
          </p>
        </b-col>
      </b-row>
    </div>

    <div class="row greenFooter-row justify-content-between mx-auto">
      <div class="col-md-6">
        <p class="greenFooter-text">
          All rights reserved. Copyright 4D Digital Green Corridors &copy; 2021
        </p>
      </div>
      <div class="col-md-6">
        <p class="greenFooter-text">Terms & Conditions &nbsp; Privacy Policy</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "greenFooter",
};
</script>

<style scoped>
.greenFooter {
  background: #000;
  color: #f8f9fa;
  width: 100%;
}

.greenFooter .center {
  text-align: center;
}

.greenFooter-row {
  width: 90%;
}
.greenFooter-text {
  opacity: 0.4;
}
h1 {
  font-size: 2.5rem;
  color:#fff;
  padding-left: .8rem;
  padding-right: .8rem;
}
p {
  font-size: 1.1em;
}
</style>
